import { useNavigate } from '@remix-run/react';
import { twMerge } from 'tailwind-merge';

import LabelV2 from './label-v2';
import {
  InfoCellBorder,
  InfoCellButtonElementBorder,
} from './power-cell-border';
import {
  getCellAssetSources,
  getCellBreakpointValues,
  getCellHiddenClasses,
  getCellVisibillityClasses,
  getInfoCellClasses,
  shouldRenderByIdentifiers,
  type Identifiers,
  type InfoCellClasses,
  type CellTextLabelsV2,
  getInfoCellFlex1Classes,
  type AlignmentResponsive,
  type AlignmentOptions,
} from './power-cell-getters';
import { ResponsiveImage } from './responsive-image';
import { type TextComponent } from './responsive-text';
import type { FeedEntry } from '~/services/layout/index.server';

type InfoCellButtonPosition = 'left' | 'right';

export function InfoCellV2({
  identifiers,
  assets,
  styles,
  textLabels,
  cellClasses,
  feedRootWebLink,
  trackAnalyticsData,
  defaultStyles,
  entry,
  isLoading,
}: {
  identifiers: Identifiers;
  assets: any;
  styles: any;
  textLabels: CellTextLabelsV2;
  cellClasses: string;
  feedRootWebLink: string | undefined;
  trackAnalyticsData: Function;
  defaultStyles: any;
  entry: FeedEntry;
  isLoading?: boolean;
}) {
  const shouldRender = shouldRenderByIdentifiers({
    identifiers,
    identifierName: 'cell_info_mobile',
  });

  const navigate = useNavigate();

  if (!shouldRender) return <></>;

  const infoCellClasses: InfoCellClasses = getInfoCellClasses(cellClasses);

  const titleTextAlignment = getCellBreakpointValues({
    config: styles,
    key: 'title_text_alignment',
  }) as AlignmentResponsive;

  const TitleText = (alignment: AlignmentOptions) => (
    <div
      className={`${getCellVisibillityClasses({
        mobile: titleTextAlignment.mobile === alignment,
        tablet: titleTextAlignment.desktop === alignment,
        desktop: titleTextAlignment.desktop === alignment,
        'large-desktop': titleTextAlignment['large-desktop'] === alignment,
      })} [&_*]:line-clamp-label-line-clamp`}
    >
      <LabelV2
        responsiveText={{
          mobile: textLabels.mobile.title_text,
          tablet: textLabels.tablet.title_text,
          desktop: textLabels.desktop.title_text,
          'large-desktop': textLabels['large-desktop'].title_text,
        }}
        className={infoCellClasses.text}
        componentTextLabelTypography={
          defaultStyles?.title_text_typography as TextComponent
        }
      />
    </div>
  );

  const buttonElementHorizontalPosition = getCellBreakpointValues({
    config: styles,
    key: 'button_element_horizontal_position',
  }) as {
    mobile: InfoCellButtonPosition;
    tablet: InfoCellButtonPosition;
    desktop: InfoCellButtonPosition;
    'large-desktop': InfoCellButtonPosition;
  };

  const ButtonElement = (alignment: InfoCellButtonPosition) =>
    !feedRootWebLink ? (
      <></>
    ) : (
      <button
        className={getCellVisibillityClasses(
          getCellBreakpointValues({
            config: styles,
            key: 'button_element_switch',
          }),
          `${
            infoCellClasses.button
          } pointer-events-auto relative flex items-center overflow-hidden rounded-button_element_corner_radius bg-button_element_background_color ${getCellVisibillityClasses(
            {
              mobile: buttonElementHorizontalPosition.mobile === alignment,
              tablet: buttonElementHorizontalPosition.desktop === alignment,
              desktop: buttonElementHorizontalPosition.desktop === alignment,
              'large-desktop':
                buttonElementHorizontalPosition['large-desktop'] === alignment,
            }
          )} shrink-0`
        )}
        onClick={() => {
          trackAnalyticsData();
          navigate(feedRootWebLink);
        }}
      >
        <LabelV2
          responsiveText={{
            mobile: textLabels.mobile.button_text,
            tablet: textLabels.tablet.button_text,
            desktop: textLabels.desktop.button_text,
            'large-desktop': textLabels['large-desktop'].button_text,
          }}
          className={infoCellClasses.buttonText}
          componentTextLabelTypography={
            defaultStyles?.button_text_typography as TextComponent
          }
        />
        <ResponsiveImage
          imageSources={getCellAssetSources({
            assets: styles,
            assetKey: 'button_icon_asset',
            assetSwitchKey: 'button_icon_switch',
          })}
          altText={entry?.title && `${entry?.title} button icon`}
          classNames={`${infoCellClasses.buttonIcon} h-cell-button-icon w-cell-button-icon`}
        />
        <InfoCellButtonElementBorder />
      </button>
    );

  if (isLoading) {
    return (
      <div
        className={twMerge(
          infoCellClasses.text,
          'animate-pulse rounded-image-radius bg-skeleton_background_color'
        )}
      />
    );
  }

  return (
    <div
      className={getCellHiddenClasses({
        identifiers,
        identifier: 'cell_info_mobile',
        classNames: 'flex',
      })}
    >
      <div
        className={`flex ${getInfoCellFlex1Classes(
          titleTextAlignment
        )} items-center justify-start`}
      >
        <ResponsiveImage
          imageSources={getCellAssetSources({
            assets: assets,
            assetKey: 'badge_asset',
            assetSwitchKey: 'badge_asset_switch',
          })}
          altText="badge asset"
          classNames={`mb-cell-info-badge-b ml-cell-info-badge-l mr-cell-info-badge-r mt-cell-info-badge-t aspect-asset_aspect_ratio w-cell-info-badge h-badge_asset_height rounded-cell-info-badge`}
        />
        {ButtonElement('left')}
        {TitleText('left')}
      </div>

      <div className="flex items-center">{TitleText('center')}</div>

      <div className="flex flex-1 items-center justify-end">
        {isRightTitleText(titleTextAlignment) && TitleText('right')}
        {ButtonElement('right')}
      </div>
      <InfoCellBorder />
    </div>
  );
}

/**
 * To support tailwind's empty:contents utility, we need to determine if the
 * right title text should atemp to render.
 * This is for the case where the left title text is long so the 3 containers
 * (left, center & right) will not cut off the left title text.
 * @param titleTextAlignment - title text alignment for each breakpoint
 * @returns boolean - true if right title text should atempt to render
 */
function isRightTitleText(titleTextAlignment: AlignmentResponsive): boolean {
  return ![
    titleTextAlignment.mobile === 'left',
    titleTextAlignment.tablet === 'left',
    titleTextAlignment.desktop === 'left',
    titleTextAlignment['large-desktop'] === 'left',
  ].some((value) => !!value);
}
