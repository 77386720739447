import type { UIComponent } from '~/services/layout/index.server.ts';
import { getLimit } from '~/services/layout/layout.ts';

import GroupInfo from '../../group-info/components';
import { GroupInfoV2 } from '~/services/plugins/group_info_v2/components/index.tsx';

import HorizontalList from '~/components/horizontal-list';
import HorizontalListV2 from '../../horizontal-list/components';

import Grid from '~/components/grid';
import GridV2 from '~/services/plugins/grid_v2/components/index.tsx';

import Hero from '../../hero/components';
import HeroV2 from '~/services/plugins/hero_v2/components/index.tsx';

import List from '~/components/list';
import ListV2 from '~/services/plugins/list_v2/components/index.tsx';

import UserAccount from '~/components/user-account';

export const groupComponents = {
  'group-info-qb': GroupInfo,
  group_info_v2: GroupInfoV2,

  horizontal_list_qb: HorizontalList,
  horizontal_list_v2: HorizontalListV2,

  'grid-qb': Grid,
  grid_v2: GridV2,

  'hero-qb': Hero,
  hero_v2: HeroV2,

  'list-qb': List,
  list_v2: ListV2,

  'quick-brick-user-account-ui-component': UserAccount,
};

export const isGroupInfo = (uiComponent: UIComponent) =>
  ['group-info-qb', 'group_info_v2'].includes(uiComponent.component_type);

export const isEmptyGroup = (uiComponent: UIComponent): boolean =>
  ['empty_group_v2', 'empty_group_component'].includes(
    uiComponent.component_type
  );

export const getUiComponentsLimits = (
  uiComponents: UIComponent[]
): number[] => {
  return uiComponents.map((c) => {
    return getLimit(c.rules?.item_limit) || 0;
  });
};

const getTotalComponentsLimit = (uiComponentsLimits: number[]): number => {
  return uiComponentsLimits.reduce((acc, curr) => acc + curr, 0);
};

export function getRepeatPatternTypeAll({
  feed,
  uiComponents,
  uiComponentsLimits,
}: {
  uiComponents: UIComponent[];
  uiComponentsLimits: number[];
  feed: any;
}): {
  uiComponents: UIComponent[];
  uiComponentsLimits: number[];
} {
  const totalComponentsLimit: number =
    getTotalComponentsLimit(uiComponentsLimits);

  const entriesLeft: number = feed.entry.length - totalComponentsLimit;

  let numOfRepeats: number = Math.ceil(entriesLeft / totalComponentsLimit) || 0;

  if (numOfRepeats === Infinity) numOfRepeats = 0;

  uiComponents = Array(numOfRepeats + 1)
    .fill(uiComponents)
    .flat();

  uiComponentsLimits = Array(numOfRepeats + 1)
    .fill(uiComponentsLimits)
    .flat();

  return {
    uiComponents,
    uiComponentsLimits,
  };
}

export function getRepeatPatternTypeLast({
  feed,
  uiComponents,
  uiComponentsLimits,
  initialNumOfComponents,
  repeatNumOfLastComponents,
}: {
  uiComponents: UIComponent[];
  uiComponentsLimits: number[];
  feed: any;
  initialNumOfComponents: number;
  repeatNumOfLastComponents: number;
}): {
  uiComponents: UIComponent[];
  uiComponentsLimits: number[];
} {
  const lastComponents: UIComponent[] = uiComponents.slice(
    -repeatNumOfLastComponents
  );

  const limitsOfLastComponents: number[] =
    getUiComponentsLimits(lastComponents);

  const totalLastComponentsLimit: number = getTotalComponentsLimit(
    limitsOfLastComponents
  );

  const totalComponentsLimit: number =
    getTotalComponentsLimit(uiComponentsLimits);

  let numOfLastComponentsRepeats: number =
    Math.ceil(
      (feed.entry.length - totalComponentsLimit) / totalLastComponentsLimit
    ) || 0;

  if (numOfLastComponentsRepeats === Infinity) numOfLastComponentsRepeats = 0;

  uiComponents = [
    ...uiComponents.slice(0, initialNumOfComponents),
    ...Array(numOfLastComponentsRepeats).fill(lastComponents).flat(),
  ];

  uiComponentsLimits = getUiComponentsLimits(uiComponents);

  return {
    uiComponents,
    uiComponentsLimits,
  };
}
