import type {
  Feed,
  FeedEntry,
  UIComponent,
} from '~/services/layout/index.server.ts';
import { getPluginConfig } from '~/utils/get-plugin-configuration.ts';
import manifest from '../config/manifest.ts';
import { PowerCell } from '../../power-cell/components/index.tsx';
import useCurrentLanguage from '~/hooks/use-current-language';

export function GroupInfoV2({
  uiComponent,
  feed,
  isLoading,
}: {
  uiComponent: UIComponent;
  feed?: Feed;
  isLoading?: boolean;
}): JSX.Element {
  const currentLanguage = useCurrentLanguage();

  const config = getPluginConfig<typeof manifest>(
    manifest,
    uiComponent,
    false,
    currentLanguage
  );

  const selectable = !!config?.rules?.component_cells_selectable;

  const isEmptyFeed: boolean =
    (feed?.entry?.length === 0 || !feed?.entry) && !isLoading;

  if (uiComponent?.rules?.hide_component_if_data_is_empty && isEmptyFeed)
    return <></>;

  const entry = isLoading
    ? undefined
    : ({
        ...feed,
        title: config.localizations.title_override || feed?.title,
      } as FeedEntry);

  return (
    <section
      className={`component-${uiComponent.id} component-classes h-fit w-screen overflow-hidden border-none`}
    >
      <PowerCell
        uiComponent={uiComponent}
        entry={entry as FeedEntry}
        selectable={selectable}
        feedRootWebLink={feed?._webLink}
      />
    </section>
  );
}
