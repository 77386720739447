import { NavLink, useLoaderData } from '@remix-run/react';

import manifest from '../config/manifest';
import { getPluginConfig } from '~/utils/get-plugin-configuration';
import { getJustifyContentClassName } from '~/utils/styles';
import type { NavBarMenuListItemType } from '~/services/layout/types';
import type { loader } from '~/routes/_index';
import useCurrentLanguage from '~/hooks/use-current-language';

function getWidthClassName(size: string) {
  return size === 'fixed' ? 'w-menu-list-item' : '';
}

function NavBarMenuListItem({
  navItem,
  itemSize,
  assetSwitch,
  titleSwitch,
  assetAlignment,
  hoverAssetSwitch,
  selectedAssetSwitch,
  selectedHoverAssetSwitch,
  alignment,
}: {
  navItem: NavBarMenuListItemType;
  itemSize: string;
  assetSwitch: boolean;
  hoverAssetSwitch: boolean;
  selectedAssetSwitch: boolean;
  selectedHoverAssetSwitch: boolean;
  titleSwitch: boolean;
  assetAlignment: string;
  alignment: string;
}) {
  const { staticLinks } = useLoaderData<typeof loader>();

  const currentLanguage = useCurrentLanguage();

  const { general, localizations } = getPluginConfig<typeof manifest>(
    manifest,
    navItem,
    false,
    currentLanguage
  );

  let path = '/';

  if (general?.link_type === 'external_link') {
    path = general?.external_link;
  } else {
    const staticLink = staticLinks.find(
      (link) => link.screenId === general?.target
    );
    path = staticLink?.link || '/';
  }

  const label = localizations.label || navItem.title;

  return (
    <NavLink
      key={navItem.id}
      to={path}
      className={`nav-bar-item-${navItem.id} group`}
      tabIndex={0}
    >
      {({ isActive }) => (
        <div
          className={`${
            isActive
              ? 'border-menu-list-item-color-selected bg-menu-list-item-selected group-hover:border-menu-list-item-color-selected-hover group-hover:bg-menu-list-item-selected-hover'
              : 'border-menu-list-item-color bg-menu-list-item group-hover:border-menu-list-item-color-hover group-hover:bg-menu-list-item-hover'
          } ${getWidthClassName(
            itemSize
          )} mb-menu-list-item-b ml-menu-list-item-l mr-menu-list-item-r mt-menu-list-item-t flex items-center rounded-menu-list-item border-menu-list-item-width pb-menu-list-item-b pl-menu-list-item-l pr-menu-list-item-r pt-menu-list-item-t ${
            assetAlignment.toLowerCase() === 'left'
              ? 'flex-row'
              : 'flex-row-reverse'
          } ${getJustifyContentClassName(alignment)}`}
        >
          {assetSwitch && (
            <div
              className={`${
                isActive
                  ? `${
                      selectedAssetSwitch && 'bg-menu-list-item-asset-selected'
                    } ${
                      selectedHoverAssetSwitch &&
                      'group-hover:bg-menu-list-item-asset-selected-hover'
                    }`
                  : `bg-menu-list-item-asset ${
                      hoverAssetSwitch &&
                      'group-hover:bg-menu-list-item-asset-hover'
                    }`
              } mb-menu-list-asset-b ml-menu-list-asset-l mr-menu-list-asset-r mt-menu-list-asset-t h-menu-list-item-asset w-menu-list-item-asset bg-contain bg-center bg-no-repeat pb-menu-list-asset-b pl-menu-list-asset-l pr-menu-list-asset-r pt-menu-list-asset-t`}
            />
          )}
          {titleSwitch && label.trim().length !== 0 && (
            <p
              className={`${
                isActive
                  ? 'text-menu-list-title-color-selected menu-list-selected-text-decoration hover:menu-list-selected-hover-text-decoration group-hover:text-menu-list-title-color-selected-hover'
                  : 'text-menu-list-title-color menu-list-default-text-decoration hover:menu-list-hover-text-decoration group-hover:text-menu-list-title-color-hover'
              } font-menu_list_title text-menu-list-title-font-size leading-menu-list-title tracking-menu-list-title menu-list-title-text-transform`}
            >
              {label}
            </p>
          )}
        </div>
      )}
    </NavLink>
  );
}

export default NavBarMenuListItem;
