import { useLocation } from '@remix-run/react';

import useSupportedLanguages from '~/hooks/use-supported-languages';
import { getURLSegments } from '~/utils/validations';

/**
 * useCurrentLanguage returns a language code based on the current page pathanme.
 * If language code is not defined, the default (first supported) language will be returned.
 */
function useCurrentLanguage() {
  const { pathname } = useLocation();
  const supportedLanguages = useSupportedLanguages();

  const segments = getURLSegments(pathname);
  const urlLanguage = segments[0];

  if (supportedLanguages?.includes(urlLanguage)) {
    return urlLanguage;
  }

  return supportedLanguages[0];
}

export default useCurrentLanguage;
