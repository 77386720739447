import { useInView } from './use-is-in-view';
import { useLoaderData } from '@remix-run/react';
import type { loader as rootLoader } from '~/routes/_index.ts';
import { getIsLastComponentInScreen } from '~/services/layout/layout';

/**
 * Hook to determine if the component is in view and is the last
 * component on the screen and the client loaded feed is not loading.
 * @param ref - The component reference
 * @param isLoading - The client loaded feed loading state
 * @param UiComponentId - The ui component id
 * @returns boolean to trigger the next feed pagination
 */
export function useLastComponentInScreenPaginationCondition({
  ref,
  isLoading,
  uiComponentId,
}: {
  ref: any;
  isLoading: boolean | undefined;
  uiComponentId: string;
}): boolean {
  const { screen } = useLoaderData<typeof rootLoader>();

  const isInView: boolean = useInView({
    ref,
  });

  const isLastComponentInScreen: boolean = getIsLastComponentInScreen(
    screen?.ui_components,
    uiComponentId
  );

  return isInView && isLastComponentInScreen && !isLoading;
}
