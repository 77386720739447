import React from 'react';

import { Form, useLoaderData, useSearchParams } from '@remix-run/react';
import debounce from 'lodash.debounce';

import logger from '~/services/logger';
import { MaybeLink } from '../maybe-link.tsx';
import { useGetWebNavBarItems } from '../navigation/nav-bar.tsx';
import { addParametersToURL } from '~/services/links/index.ts';

import type { UseGetWebNavBarItems } from '../navigation/nav-bar.tsx';
import type { loader as rootLoader } from '~/routes/_index.tsx';
import { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useIsSearchScreen } from '~/hooks/use-is-search-screen.ts';
import { getNavItemAnalyticsData } from '~/utils/analytics.tsx';
import { track } from '~/services/analytics/index.client.ts';
import { getTranslations } from '~/services/localization/index.ts';
import { validateSearchQuery } from '~/services/layout/layout.ts';
import useCurrentLanguage from '~/hooks/use-current-language.ts';

export default function Search(): JSX.Element {
  const { searchData, isThemeV2, analyticsTrackData } =
    useLoaderData<typeof rootLoader>();
  const [searchParams, setSearchParams] = useSearchParams();

  const inputRef = useRef<HTMLInputElement>(null);
  const honeypotInputRef = useRef<HTMLInputElement>(null);

  const [showCancelIconButton, setShowCancelIconButton] = useState<boolean>(
    !!searchParams.get('q')
  );

  const currentLanguage = useCurrentLanguage();

  const {
    searchScreenStyles,
    localizations,
    minimumQueryLength,
    maximumQueryLength,
    searchScreenId,
    pathname,
    autofocusEnabled,
    q,
  } = searchData as any;

  const searchIconAsset = searchScreenStyles?.search_icon_asset;

  const translations = getTranslations(localizations, currentLanguage);

  const searchPlaceholder = translations?.placeholder_message || '';

  const style: any = { textAlign: `var(--search_label_text_alignment)` };

  const onChange = debounce((event) => {
    const value = event.target.value;
    if (value) setShowCancelIconButton(true);

    if (honeypotInputRef.current?.value !== '') return;
    if (!validateSearchQuery(value)) return;
    if (value.length < minimumQueryLength || value.length > maximumQueryLength)
      return;

    track('search_executed', {
      ...analyticsTrackData,
      query_length: value?.length,
      query_text: value,
    });

    setSearchParams({ q: value });
  }, searchData.debounce as number | undefined);

  const resetSearchParams = () => {
    if (!inputRef.current) return;
    track('search_canceled', {
      ...analyticsTrackData,
      query_length: inputRef.current.value?.length,
      query_text: inputRef.current.value,
    });
    inputRef.current.value = '';
    inputRef.current.focus();
    setShowCancelIconButton(false);
    setSearchParams({ q: '' });
  };

  return (
    <section
      role="search"
      aria-label="Search Component"
      className={twMerge(
        `mr-search-r mt-search-t mb-search-b top-0 ml-search-l w-screen search-${searchScreenId} border-none`,
        'ml-search-mobile-l lg:ml-search-l',
        !isThemeV2 && 'sticky z-40'
      )}
      style={style}
    >
      <Form method="get" action={pathname}>
        <div className="relative mb-search-label-b ml-search-label-l mr-search-label-r mt-search-label-t">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            {searchScreenStyles?.search_icon_enabled && searchIconAsset && (
              <img
                className="inline-block h-search-icon-height w-search-icon-width"
                src={searchIconAsset}
                alt="search back icon"
              />
            )}
          </div>
          <input
            ref={honeypotInputRef}
            className="honeypot"
            type="text"
            tabIndex={-1}
            autoComplete="off"
            name="name"
          />
          <input
            ref={inputRef}
            autoFocus={autofocusEnabled}
            name="q"
            key={q}
            defaultValue={q}
            type="search"
            id="default-search"
            style={{ outline: 'none' }}
            className={`w-full rounded-search-bar-radius bg-search-bar pb-search-bar-b pl-search-bar-l pr-search-bar-r pt-search-bar-t font-search-font-family text-search-font-size text-search-color search-label-text-transform placeholder:text-search-placeholder-color focus:bg-search-bar-focus placeholder:focus:text-search-focused-color`}
            placeholder={searchPlaceholder}
            onChange={onChange}
          />
          {showCancelIconButton && (
            <div
              className={`absolute inset-y-0 right-10 mb-cancel-icon-b ml-cancel-icon-l mr-cancel-icon-r mt-cancel-icon-t flex items-center bg-transparent`}
            >
              <img
                alt="cancel"
                src={searchScreenStyles.cancel_icon_asset}
                className="inline-block h-cancel-icon w-cancel-icon cursor-pointer"
                onClick={resetSearchParams}
              />
            </div>
          )}
        </div>
      </Form>
    </section>
  );
}

export interface SearchScreen {
  q: string | null;
  pathname: string;
  searchScreenId: string | null;
  searchScreenStyles: any | null;
}

/**
 * search_icon_asset & link handler
 * @returns
 */
export function SearchIconAsset(): JSX.Element {
  const { navbarSearchButton }: UseGetWebNavBarItems = useGetWebNavBarItems();

  const { debug, debugLayoutId, analyticsTrackData } =
    useLoaderData<typeof rootLoader>();

  const searchIconAsset = navbarSearchButton?.searchIconAsset;
  const searchScreenId = navbarSearchButton?.searchScreenId;
  const isSearchScreen = useIsSearchScreen(searchScreenId);
  const trackData = React.useMemo(
    () =>
      getNavItemAnalyticsData('tap_menu', analyticsTrackData, {
        selected_area: 'Search',
      }),
    [analyticsTrackData]
  );

  if (!searchScreenId || !searchIconAsset) return <></>;

  if (isSearchScreen) return <></>;

  try {
    const linkToUrl = addParametersToURL('/search', {
      debug,
      'layout-id': debugLayoutId,
    });

    return (
      <>
        <div
          className={`flex flex-1 justify-end self-start lg:self-auto search-icon-main-${searchScreenId}`}
        >
          <MaybeLink
            to={linkToUrl}
            linkariainfo="Search"
            analyticsdata={trackData}
          >
            <img
              src={searchIconAsset}
              alt="Search Icon"
              className="mb-search-icon-main-b ml-search-icon-main-l mt-search-icon-main-t h-search-icon-main w-search-icon-main lg:mr-search-icon-main-r"
            />
          </MaybeLink>
        </div>
      </>
    );
  } catch (error: any) {
    logger.info(`SearchIconAsset: ${error.message}`);
    return <></>;
  }
}
