import logger from '~/services/logger';
import type { Feed, UIComponent } from '~/services/layout/index.server.ts';
import { GroupComponents } from './group-components';
import { isEmptyGroup } from './helpers';
import { MaybeEmptyGroup } from './maybe-empty-group';

export default function Group({
  uiComponent,
  feed,
  isLoading,
  isClient,
}: {
  uiComponent: UIComponent;
  feed?: Feed;
  isLoading?: boolean;
  isClient?: boolean;
}): JSX.Element {
  try {
    const isGroupComponent: boolean = uiComponent.component_type === 'group-qb';

    const isEmpty: boolean = feed?.entry?.length === 0 || !feed?.entry;

    const hideComponentWhenEmpty: boolean =
      uiComponent?.rules?.hide_component_if_data_is_empty;

    const hasEmptyGroupComponent: boolean =
      uiComponent?.ui_components?.some(isEmptyGroup);

    if (
      !isLoading &&
      (!isGroupComponent ||
        (isEmpty && hideComponentWhenEmpty && !hasEmptyGroupComponent))
    ) {
      return <></>;
    }

    const emptyGroupComponent = uiComponent?.ui_components?.find(isEmptyGroup);

    const groupComponents = uiComponent?.ui_components?.filter(
      (c) => !isEmptyGroup(c)
    );

    const uiComponentId = uiComponent.id;

    return (
      <div
        className={`group-${uiComponentId} mb-group-b mt-group-t bg-group pb-group-b pl-group-l pr-group-r pt-group-t`}
      >
        <MaybeEmptyGroup uiComponent={emptyGroupComponent} feed={feed} />

        <GroupComponents
          uiComponents={groupComponents}
          feed={feed}
          repeatPatternType={uiComponent?.rules?.repeat_pattern_type}
          repeatNumOfLastComponents={
            uiComponent?.rules?.repeat_num_of_components
          }
          isClient={isClient}
          isLoading={isLoading}
        />
      </div>
    );
  } catch (error: any) {
    logger.info(`Group: ${error.message}`);
    return <></>;
  }
}
