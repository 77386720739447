import logger from '~/services/logger';
import Grid from './grid.tsx';
import Group from '~/services/plugins/group-qb/components/index.tsx';
import Hero from '../services/plugins/hero/components/index.tsx';
import List from './list.tsx';
import HorizontalList from './horizontal-list/index.tsx';
import UserAccount from './user-account.tsx';
import {
  actionToComponentType,
  getIsClientLoaded,
} from '~/services/ui-components/index.ts';

import type {
  Feed,
  UIComponent,
  UIComponentType,
} from '~/services/layout/index.server.ts';
import ClientUiComponent from './client/client-ui-component.client.tsx';
import { ClientOnly } from 'remix-utils/client-only';
import { EPG } from './epg/index.tsx';
import GridV2 from '~/services/plugins/grid_v2/components/index.tsx';
import HeroV2 from '~/services/plugins/hero_v2/components/index.tsx';
import HorizontalListV2 from '~/services/plugins/horizontal-list/components/index.tsx';
import WebviewComponent from '~/services/plugins/web-view-component/components/index.tsx';
import PaymentInfo from '~/services/plugins/payment-info/components/index.tsx';
import SubscriptionManagement from '~/services/plugins/subscription-management/components/index.tsx';
import { GroupInfoV2 } from '~/services/plugins/group_info_v2/components/index.tsx';
import { useIsLoggedIn } from '~/hooks/use-is-logged-in.ts';
import ClientStorageUiComponent from './client/client-storage-ui-component.client.tsx';
import ListV2 from '~/services/plugins/list_v2/components/index.tsx';

export type Props = {
  uiComponent: UIComponent;
  feed: Feed | null | undefined;
};

const UIComponents: Record<
  UIComponentType,
  ((props: any) => JSX.Element) | null
> = {
  horizontal_list_qb: HorizontalList,
  'list-qb': List,
  'hero-qb': Hero,
  'group-qb': Group,
  'grid-qb': Grid,
  grid_v2: GridV2,
  list_v2: ListV2,
  hero_v2: HeroV2,
  'quick-brick-user-account-ui-component': UserAccount,
  epg_component: EPG,
  webview_component: WebviewComponent,
  empty_group_component: null,
  'group-info-qb': null,
  horizontal_list_v2: HorizontalListV2,
  'payment-info': PaymentInfo,
  group_info_v2: GroupInfoV2,
  empty_group_v2: null,
  'subscription-management': SubscriptionManagement,
};

export default function UiComponent({ uiComponent, feed }: Props): JSX.Element {
  const { isLoggedIn } = useIsLoggedIn();

  try {
    const isClientLoaded = getIsClientLoaded(uiComponent);

    const Component = UIComponents[uiComponent.component_type];

    if (!Component) return <></>;

    if (isClientLoaded && !!isLoggedIn) {
      const componentType = actionToComponentType[uiComponent.data?.source];

      if (
        componentType === 'favorites' ||
        componentType === 'continue-watching'
      ) {
        return (
          <ClientStorageUiComponent
            Component={Component}
            uiComponent={uiComponent}
            storageKey={componentType}
          />
        );
      }
    }

    if (
      uiComponent?.rules?.load_feed_from_client ||
      uiComponent?.rules?.load_feed_from_client_switch
    ) {
      return (
        <ClientOnly fallback={<></>}>
          {() => (
            <ClientUiComponent
              Component={Component}
              uiComponent={uiComponent}
            />
          )}
        </ClientOnly>
      );
    }

    if (!feed) return <></>;

    return <Component uiComponent={uiComponent} feed={feed} />;
  } catch (error: any) {
    logger.info(`UiComponent: ${error.message}`);
    return <></>;
  }
}
