import type { FeedEntry, UIComponent } from '~/services/layout/index.server.ts';
import {
  getUiComponentsLimits,
  getRepeatPatternTypeAll,
  getRepeatPatternTypeLast,
  groupComponents,
} from './helpers';
import { getGroupComponentsAndFeedEntries } from './get-group-components-and-feed-entries';

/**
 * Group components
 * @param uiComponents - Ui components in the group
 * @param feed - Feed object
 * @param repeatPatternType - Repeat pattern type of the group
 * @param repeatNumOfLastComponents - Number of last components to repeat
 * @param isClient - Is client side rendering
 * @param isLoading - Is client side loading
 * @returns Group w/ components
 */
export function GroupComponents({
  uiComponents,
  feed,
  repeatPatternType,
  repeatNumOfLastComponents,
  isClient,
  isLoading,
}: {
  feed?: any;
  uiComponents: UIComponent[];
  repeatPatternType: 'no' | 'all' | 'last';
  repeatNumOfLastComponents?: number;
  isClient?: boolean;
  isLoading?: boolean;
}): JSX.Element {
  const feedLength = feed?.entry?.length || 0;

  if (!feed || feedLength === 0) return <></>;
  if (!Array.isArray(uiComponents) || uiComponents.length === 0) return <></>;

  const initialNumOfComponents: number = uiComponents.length;

  let uiComponentsLimits: number[] = getUiComponentsLimits(uiComponents);

  if (repeatPatternType === 'all') {
    const repeatPatternTypeAll: {
      uiComponents: UIComponent[];
      uiComponentsLimits: number[];
    } = getRepeatPatternTypeAll({
      feed,
      uiComponents,
      uiComponentsLimits,
    });

    uiComponents = repeatPatternTypeAll.uiComponents;
    uiComponentsLimits = repeatPatternTypeAll.uiComponentsLimits;
  }

  if (repeatPatternType === 'last' && repeatNumOfLastComponents) {
    const repeatPatternTypeLast: {
      uiComponents: UIComponent[];
      uiComponentsLimits: number[];
    } = getRepeatPatternTypeLast({
      feed,
      uiComponents,
      uiComponentsLimits,
      initialNumOfComponents,
      repeatNumOfLastComponents,
    });

    uiComponents = repeatPatternTypeLast.uiComponents;
    uiComponentsLimits = repeatPatternTypeLast.uiComponentsLimits;
  }

  const uiComponentsAndEntries: {
    uiComponent: UIComponent;
    feedEntry: FeedEntry;
  }[] = getGroupComponentsAndFeedEntries({
    feed,
    uiComponents,
    uiComponentsLimits,
    initialNumOfComponents,
  });

  return (
    <>
      {uiComponentsAndEntries?.map((uiComponentAndEntry, i) => {
        const entry: FeedEntry = uiComponentAndEntry.feedEntry;
        const uiComponent: UIComponent = uiComponentAndEntry.uiComponent;
        const componentType: string = uiComponent.component_type;

        const Comp =
          groupComponents[componentType as keyof typeof groupComponents];

        if (!Comp) return <></>;

        return (
          <Comp
            key={i}
            isLoading={isLoading}
            uiComponent={uiComponent}
            feed={{
              ...feed,
              entry,
            }}
            isClient={isClient}
          />
        );
      })}
    </>
  );
}
