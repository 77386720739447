import { useEffect } from 'react';
import type { Feed, FeedEntry } from '~/services/layout/index.server.ts';
import {
  filterSkeletonEntries,
  generateSkeletonEntries,
  getLimit,
} from '~/services/layout/layout';

export function useFeedEntries({
  feedEntries,
  setFeedEntries,
  isLoading,
  isClient,
  feed,
  itemLimit,
  circularCondition,
}: {
  feedEntries: FeedEntry[];
  setFeedEntries: any;
  isLoading?: boolean | undefined;
  isClient?: boolean | undefined;
  feed: Feed | undefined;
  itemLimit: number | undefined;
  circularCondition?: boolean;
}) {
  const feedEntriesLength = feed?.entry?.length || 0;

  const entries: FeedEntry[] | [] =
    isLoading && isClient
      ? generateSkeletonEntries()
      : feed?.entry?.slice(0, getLimit(itemLimit)) || [];

  useEffect(() => {
    if (!isLoading || !isClient) {
      setFeedEntries(entries);
      return;
    }

    setFeedEntries((prevFeedEntries: any[]) => {
      return filterSkeletonEntries(prevFeedEntries);
    });
  }, [isLoading]);

  if (feedEntriesLength === 0) return [];

  const res = feedEntries?.length > entries?.length ? feedEntries : entries;

  if (typeof circularCondition === 'undefined') return res;

  return circularCondition ? res : res.slice(0, getLimit(itemLimit));
}

export function useFeedEntriesV1({
  initialFeedEntries,
  setFeedEntries,
}: {
  initialFeedEntries: FeedEntry[] | [];
  setFeedEntries: any;
}) {
  useEffect(() => {
    setFeedEntries(initialFeedEntries);
  }, []);
}
