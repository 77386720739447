import logger from '~/services/logger';
import { getURLSegments } from './validations';

export const DEFAULT_LANGUAGE = 'en';

/**
 * getSupportedLanguages returns languages supported by
 * the application defined in the envrionment variable `languages`.
 * In case of exception - function returns English as a default language.
 */
export function getSupportedLanguages(): string[] {
  const languagesString = process?.env?.languages;

  if (!languagesString) return [DEFAULT_LANGUAGE];

  try {
    return JSON.parse(languagesString);
  } catch (err) {
    logger.error(`Failed to parse languages env var, error: ${err}`);

    return [DEFAULT_LANGUAGE];
  }
}

/**
 * getURLLanguage returns first segment of the URL assuming
 * it's a language code. If the retreived language code is not
 * supported - function returns 'unknown' string.
 */
export function getURLLanguage(pathname: string): string | 'unknown' {
  const segments = getURLSegments(pathname);
  const supportedLanguages = getSupportedLanguages();

  const urlLanguage = supportedLanguages.includes(segments[0])
    ? segments[0]
    : 'unknown';

  return urlLanguage;
}
