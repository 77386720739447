import type { FeedEntry, UIComponent } from '~/services/layout/index.server';
import { twMerge } from 'tailwind-merge';
import { MaybeLink } from '~/components/maybe-link';
import { ResponsiveImage } from './responsive-image';
import logger from '~/services/logger';
import { RuntimeV2 } from './runtime-v2';
import {
  type TapCellAnalyticsData,
  useTapCellAnalyticsData,
} from '~/hooks/use-tap-cell-analytics-data';
import { ProgressBarV2 } from './progress-bar-v2';
import { ActionIconV2 } from './action-icon-v2';
import {
  getCellClasses,
  getCellTextLabelClasses,
  getCellTextLabelsV2,
  getCellEntryImgSources,
  getCellBreakpointValues,
  getCellAssetSources,
  getCellContainerCellTypeClasses,
  getCellImageCellTypeClasses,
  getCell3TextLabelContainerClasses,
  getCellHiddenClasses,
  getCellImagePlaceholderColorClasses,
  getCellImageAspectRatioClasses,
  cellTextLabels,
  getResponsiveCellValues,
  getPointerEventsClasses,
  getCellImagePlaceholderConditions,
  type CellTextLabelsV2,
  type ResponsiveSources,
  getCellImageContainerClasses,
  getCell3IgnorePaddingClasses,
} from './power-cell-getters';
import { useLoaderData } from '@remix-run/react';
import type { loader as rootLoader } from '~/routes/_index.ts';
import { getResponsiveCellConfigs } from './get-responsive-cell-configs';
import { seoSelectedBreakpoint } from '~/services/layout/layout';
import { LockBadge } from './lock-badge';
import { LabelWrapper } from './label-wrapper';
import { useTrackAnalyticsData } from '~/hooks/use-track-analytics-data';
import { PowerCellBorder } from './power-cell-border';
import {
  useResponsiveBreakpoint,
  type UseResponsiveBreakpoint,
} from '~/hooks/use-responsive-breakpoint';
import { useCWProgress } from '~/hooks/use-cw-progress';
import { InfoCellV2 } from './info-cell-v2';

export function PowerCell({
  uiComponent,
  entry,
  selectable,
  cellClassNames,
  cellRef,
  feedRootWebLink,
  isLoading,
}: {
  uiComponent: UIComponent;
  entry: FeedEntry;
  selectable?: boolean;
  cellClassNames?: string;
  cellRef?: any;
  feedRootWebLink?: string;
  isLoading?: boolean;
}): JSX.Element {
  const { cellStyles, timeZoneHeader } = useLoaderData<typeof rootLoader>();

  const progress: number | undefined = useCWProgress(entry);

  isLoading = isLoading || entry?._isLoadingSkeletonEntry;

  const tapCellAnalyticsData: TapCellAnalyticsData = useTapCellAnalyticsData({
    uiComponent,
    entry,
  });

  const trackAnalyticsData: Function =
    useTrackAnalyticsData(tapCellAnalyticsData);

  const responsiveBreakpoint: UseResponsiveBreakpoint =
    useResponsiveBreakpoint();

  try {
    const { identifiers, styles, assets } = getResponsiveCellConfigs({
      cellStyles,
      uiComponent,
    });

    const defaultStyles = styles[seoSelectedBreakpoint];

    const cellClasses: string = getCellClasses(uiComponent);

    const textLabelClasses = getCellTextLabelClasses(cellClasses);

    const imageSources: ResponsiveSources = getCellEntryImgSources({
      assets,
      entry,
    });

    const textLabels: CellTextLabelsV2 = getCellTextLabelsV2({
      entry,
      styles,
      identifiers,
      timeZoneHeader,
      isLoading,
    });

    const textLabelsAlignment = getResponsiveCellValues({
      cellKeys: cellTextLabels,
      cellKeysNameSuffix: 'text_alignment',
      config: styles,
      identifiers,
    });

    const entryWebLink: string = entry?._webLink || '';

    return (
      <div
        className={getPointerEventsClasses({
          assets,
          identifiers,
        })}
        data-testid={`power-cell-${entry?.id}-${uiComponent?.id}`}
      >
        <MaybeLink
          to={entryWebLink}
          enabled={selectable}
          linkariainfo={entry?.title}
          analyticsdata={tapCellAnalyticsData}
        >
          <div
            ref={cellRef}
            className={twMerge(
              cellClasses,
              'z-0',
              'component-cell-width relative shrink-0 overflow-hidden rounded-cell-border-radius bg-cell pb-cell-b pl-cell-l pr-cell-r pt-cell-t',
              !!entryWebLink && 'hover:bg-cell-hover',
              'rounded-cell-radius',
              'group',

              'cell-1-mobile',
              'cell-1-tablet',
              'cell-1-desktop',
              'cell-1-large-desktop',

              'cell-2-mobile',
              'cell-2-tablet',
              'cell-2-desktop',
              'cell-2-large-desktop',

              'cell-3-mobile',
              'cell-3-tablet',
              'cell-3-desktop',
              'cell-3-large-desktop',

              'cell-info-mobile',
              'cell-info-tablet',
              'cell-info-desktop',
              'cell-info-large-desktop',

              cellClassNames
            )}
          >
            <PowerCellBorder isPowerCellBorderHover={!!entryWebLink} />
            <InfoCellV2
              identifiers={identifiers}
              assets={assets}
              styles={styles}
              textLabels={textLabels}
              cellClasses={cellClasses}
              feedRootWebLink={feedRootWebLink}
              trackAnalyticsData={trackAnalyticsData}
              defaultStyles={defaultStyles}
              entry={entry}
              isLoading={isLoading}
            />
            <div
              className={getCellContainerCellTypeClasses({
                identifiers,
                assets,
              })}
            >
              <div
                className={`relative rounded-image-border-radius bg-transparent ${getCellImageContainerClasses(
                  {
                    identifiers,
                    assets,
                  }
                )}`}
              >
                <LockBadge entry={entry} assets={assets} />
                <RuntimeV2 entry={entry} styles={styles} />
                <ActionIconV2 assets={assets} entry={entry} />
                <ResponsiveImage
                  imageSources={getCellBreakpointValues({
                    config: assets,
                    key: 'image_overlay',
                    prevBreakpointFallback: true,
                  })}
                  altText={entry?.title && `${entry?.title} overlay`}
                  classNames="z-[3] absolute aspect-cell-image w-full object-cover"
                />
                <ContentBadge entry={entry} assets={assets} />
                <CellImage
                  entry={entry}
                  cellClasses={cellClasses}
                  entryWebLink={entryWebLink}
                  assets={assets}
                  identifiers={identifiers}
                  imageSources={imageSources}
                  isLoading={isLoading}
                />
                <ProgressBarV2 assets={assets} progress={progress} />
              </div>

              {/* Cell 1 and 2 Labels */}
              <div className="z-[6] flex flex-1 flex-col justify-center break-words">
                <LabelWrapper
                  textLabelNames={[
                    'text_label_1',
                    'text_label_2',
                    'text_label_3',
                    'text_label_4',
                  ]}
                  assets={assets}
                  textLabels={textLabels}
                  textLabelsAlignment={textLabelsAlignment}
                  textLabelClasses={textLabelClasses}
                  defaultStyles={defaultStyles}
                  entryWebLink={entryWebLink}
                  trackAnalyticsData={trackAnalyticsData}
                  identifiers={identifiers}
                  responsiveBreakpoint={responsiveBreakpoint}
                  entry={entry}
                  isLoading={isLoading}
                />
              </div>
              {/* End Cell 1 and 2 Labels */}

              {/* Cell 3 Lables */}
              <div
                className={twMerge(
                  getCellHiddenClasses({
                    identifiers,
                    identifier: 'cell_3_v2',
                    classNames: 'absolute left-0 top-0 z-[6] w-full',
                  }),
                  getCell3IgnorePaddingClasses(identifiers, 'top')
                )}
              >
                <LabelWrapper
                  textLabelNames={[
                    'top_text_label_1',
                    'top_text_label_2',
                    'top_text_label_3',
                  ]}
                  assets={assets}
                  textLabels={textLabels}
                  textLabelsAlignment={textLabelsAlignment}
                  textLabelClasses={textLabelClasses}
                  defaultStyles={defaultStyles}
                  entryWebLink={entryWebLink}
                  trackAnalyticsData={trackAnalyticsData}
                  identifiers={identifiers}
                  responsiveBreakpoint={responsiveBreakpoint}
                  entry={entry}
                  isLoading={isLoading}
                />
              </div>

              <div
                className={getCell3TextLabelContainerClasses({
                  identifiers,
                  classNames:
                    'absolute left-1/2 top-1/2 z-[6] w-full -translate-x-1/2 -translate-y-1/2 transform',
                })}
              >
                <LabelWrapper
                  textLabelNames={[
                    'center_text_label_1',
                    'center_text_label_2',
                    'center_text_label_3',
                  ]}
                  assets={assets}
                  textLabels={textLabels}
                  textLabelsAlignment={textLabelsAlignment}
                  textLabelClasses={textLabelClasses}
                  defaultStyles={defaultStyles}
                  entryWebLink={entryWebLink}
                  trackAnalyticsData={trackAnalyticsData}
                  identifiers={identifiers}
                  responsiveBreakpoint={responsiveBreakpoint}
                  entry={entry}
                  isLoading={isLoading}
                />
              </div>

              <div
                className={twMerge(
                  getCell3TextLabelContainerClasses({
                    identifiers,
                    classNames: 'absolute bottom-0 left-0 z-[6] w-full',
                  }),
                  getCell3IgnorePaddingClasses(identifiers, 'bottom')
                )}
              >
                <LabelWrapper
                  textLabelNames={[
                    'bottom_text_label_1',
                    'bottom_text_label_2',
                    'bottom_text_label_3',
                  ]}
                  assets={assets}
                  textLabels={textLabels}
                  textLabelsAlignment={textLabelsAlignment}
                  textLabelClasses={textLabelClasses}
                  defaultStyles={defaultStyles}
                  entryWebLink={entryWebLink}
                  trackAnalyticsData={trackAnalyticsData}
                  identifiers={identifiers}
                  responsiveBreakpoint={responsiveBreakpoint}
                  entry={entry}
                  isLoading={isLoading}
                />
              </div>
              {/* End Cell 3 Lables */}
            </div>
          </div>
        </MaybeLink>
      </div>
    );
  } catch (error: any) {
    logger.info(`PowerCell: ${error.message}`);
    return <></>;
  }
}

function ContentBadge({ entry, assets }: { entry: any; assets: any }) {
  return (
    <div className="absolute z-[3] mb-content-badge-margin-bottom ml-content-badge-margin-left mr-content-badge-margin-right mt-content-badge-margin-top aspect-cell-image w-full">
      <ResponsiveImage
        imageSources={getCellAssetSources({
          assets,
          assetKey: 'static_badge',
          assetSwitchKey: 'content_badge_switch',
        })}
        altText={entry?.title && `${entry?.title} badge`}
        classNames="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform w-content-badge-width h-content-badge-height"
      />
    </div>
  );
}

function CellImage({
  entry,
  cellClasses,
  entryWebLink,
  assets,
  identifiers,
  imageSources,
  isLoading,
}: {
  entry: any;
  cellClasses: any;
  entryWebLink: any;
  assets: any;
  identifiers: any;
  imageSources: any;
  isLoading?: boolean;
}) {
  if (isLoading) {
    const {
      mobile,
      tablet,
      desktop,
      'large-desktop': largeDesktop,
    } = getCellImagePlaceholderConditions({ assets, identifiers });

    return (
      <div
        className={twMerge(
          getCellImageAspectRatioClasses({ assets, identifiers }),
          'animate-pulse rounded-image-radius bg-skeleton_background_color',

          'relative rounded-image-radius',
          identifiers.mobile === 'cell_2_v2' &&
            'mobile:max-tablet:group-[.cell-2-mobile]:w-image-width',
          identifiers.tablet === 'cell_2_v2' &&
            'tablet:max-desktop:group-[.cell-2-tablet]:w-image-width',
          identifiers.desktop === 'cell_2_v2' &&
            'desktop:max-large-desktop:group-[.cell-2-desktop]:w-image-width',
          identifiers['large-desktop'] === 'cell_2_v2' &&
            'large-desktop:group-[.cell-2-large-desktop]:w-image-width',

          identifiers.mobile === 'cell_2_v2' &&
            'mobile:max-tablet:group-[.cell-2-mobile]:flex',
          identifiers.tablet === 'cell_2_v2' &&
            'tablet:max-desktop:group-[.cell-2-tablet]:flex',
          identifiers.desktop === 'cell_2_v2' &&
            'desktop:max-large-desktop:group-[.cell-2-desktop]:flex',
          identifiers['large-desktop'] === 'cell_2_v2' &&
            'large-desktop:group-[.cell-2-large-desktop]:flex',

          !mobile && 'mobile:max-tablet:hidden',
          !tablet && 'tablet:max-desktop:hidden',
          !desktop && 'desktop:max-large-desktop:hidden',
          !largeDesktop && 'large-desktop:hidden'
        )}
      />
    );
  }

  return (
    <div
      className={twMerge(
        getCellImagePlaceholderColorClasses({
          assets,
          identifiers,
          imageSources,
        }),
        getCellImageAspectRatioClasses({ assets, identifiers }),
        'relative rounded-image-radius',
        identifiers.mobile === 'cell_2_v2' &&
          'mobile:max-tablet:group-[.cell-2-mobile]:w-image-width',
        identifiers.tablet === 'cell_2_v2' &&
          'tablet:max-desktop:group-[.cell-2-tablet]:w-image-width',
        identifiers.desktop === 'cell_2_v2' &&
          'desktop:max-large-desktop:group-[.cell-2-desktop]:w-image-width',
        identifiers['large-desktop'] === 'cell_2_v2' &&
          'large-desktop:group-[.cell-2-large-desktop]:w-image-width',

        identifiers.mobile === 'cell_2_v2' &&
          'mobile:max-tablet:group-[.cell-2-mobile]:flex',
        identifiers.tablet === 'cell_2_v2' &&
          'tablet:max-desktop:group-[.cell-2-tablet]:flex',
        identifiers.desktop === 'cell_2_v2' &&
          'desktop:max-large-desktop:group-[.cell-2-desktop]:flex',
        identifiers['large-desktop'] === 'cell_2_v2' &&
          'large-desktop:group-[.cell-2-large-desktop]:flex'
      )}
    >
      <div
        className={twMerge(
          identifiers.mobile === 'cell_2_v2' &&
            'mobile:max-tablet:group-[.cell-2-mobile]:flex-shrink-0',
          identifiers.tablet === 'cell_2_v2' &&
            'tablet:max-desktop:group-[.cell-2-tablet]:flex-shrink-0',
          identifiers.desktop === 'cell_2_v2' &&
            'desktop:max-large-desktop:group-[.cell-2-desktop]:flex-shrink-0',
          identifiers['large-desktop'] === 'cell_2_v2' &&
            'large-desktop:group-[.cell-2-large-desktop]:flex-shrink-0'
        )}
      >
        <ResponsiveImage
          imageSources={imageSources}
          altText={entry?.title}
          classNames={getCellImageCellTypeClasses({
            identifiers,
            cellClasses,
          })}
          powerCellImage={{ isPowerCellImageHover: !!entryWebLink }}
        />
      </div>
    </div>
  );
}
