import { z } from 'zod';

/**
 * Validates if the input is a valid Zod URL & starts with http which covers both http & https
 * @param url string or URL object to validate
 * @returns boolean
 */
export function isValidUrl(url: string | URL | undefined | null): boolean {
  if (!url) return false;

  if (url instanceof URL) url = url.href;

  if (typeof url !== 'string') return false;

  if (!url.startsWith('http')) return false;

  const urlZodSchema: z.ZodString = z.string().url();

  const checkUrl = urlZodSchema.safeParse(url);

  return checkUrl.success;
}

export function getURLSegments(url: string) {
  return url?.split('/')?.filter((segment) => segment !== '');
}
